import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  initiatePurchaseData: null,
};

export const getInitiatePurchase = createAsyncThunk(
  "initiatePurchase/getInitiatePurchase",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`initiate-authentication`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initiatePurchaseSlice = createSlice({
  name: "initiatePurchase",
  initialState,
  reducers: {},
  extraReducers: {
    [getInitiatePurchase.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getInitiatePurchase.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.initiatePurchaseData = action.payload?.data;
    },
    [getInitiatePurchase.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default initiatePurchaseSlice.reducer;
