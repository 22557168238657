import { useEffect } from "react";
import { getgiftCard } from "../store/slices/giftCardSlice";
import { useDispatch, useSelector } from "react-redux";

export const useGiftCard = () => {
  const dispatch = useDispatch();
  const giftCardData = useSelector((state) => state.giftCard);

  useEffect(() => {
    if (giftCardData.status === "idle") {
      dispatch(getgiftCard());
    }
  }, []);

  return {
    giftCardData: giftCardData?.giftCardData,
  };
};
