import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  formData: {},
};

const formPersistSlice = createSlice({
  name: "formData",
  initialState,
  reducers: {
    saveFormValue: (state, action) => {
      state.formData = action.payload;
    },
    clearForm: (state) => {
      state.formData = {};
    },
  },
  extraReducers: {},
});
export const { saveFormValue, clearForm } = formPersistSlice.actions;

export default formPersistSlice.reducer;
