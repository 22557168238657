import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  giftCardData: [],
};

export const getgiftCard = createAsyncThunk(
  "giftCard/getgiftCard",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`gift-cards`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const giftCardSlice = createSlice({
  name: "giftCard",
  initialState,
  reducers: {},
  extraReducers: {
    [getgiftCard.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getgiftCard.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.giftCardData = action.payload?.data;
    },
    [getgiftCard.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default giftCardSlice.reducer;
