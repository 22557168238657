import { useEffect } from "react";
import { getlistAmount } from "../store/slices/listAmountSlice";
import { useDispatch, useSelector } from "react-redux";

export const useListAmount = () => {
  const dispatch = useDispatch();
  const listAmountData = useSelector((state) => state.listAmount);

  useEffect(() => {
    if (listAmountData.status === "idle") {
      dispatch(getlistAmount());
    }
  }, []);

  return {
    listAmountData: listAmountData?.listAmountData,
  };
};
