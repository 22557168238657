import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  listAmountData: null,
};

export const getlistAmount = createAsyncThunk(
  "listAmount/getlistAmount",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`amount`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const listAmountSlice = createSlice({
  name: "listAmount",
  initialState,
  reducers: {},
  extraReducers: {
    [getlistAmount.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getlistAmount.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.listAmountData = action.payload?.data;
    },
    [getlistAmount.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default listAmountSlice.reducer;
